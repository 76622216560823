"use client";

import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      style={{ zIndex: 2147483647 }}
      className="toaster tstz group relative z-[1000]"
      toastOptions={{
        classNames: {
          toast:
            "group z-[100] toast group-[.toaster]:bg-white group-[.toaster]:text-primary-700 group-[.toaster]:border-border-[#ddd] group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-primary-600 z-[100]",
          actionButton: "group-[.toast]:bg-primary-700 group-[.toast]:text-white",
          cancelButton: "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground"
        }
      }}
      {...props}
    />
  );
};

export default Toaster;
