import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Analytics/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ClientScripts.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/context/queryClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/context/ReactQueryDevTools.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/CalSans-SemiBold.woff2\",\"variable\":\"--font-heading\"}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/Matter-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/Matter-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/Matter-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../fonts/Matter-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"matter\"}");
